// Step 1: Import React
import React, { useEffect, useState } from "react";
import { Container, Row, Col } from "react-bootstrap";
import {Layout} from "../../../components/Layout"
import Button from '../../../components/buttons/button'
import ProductBanner from "../../../components/product_banners/product_banners";
import SlickProduct from "../../../components/product_slick/slick_product";
import Faq from "../../../components/faq/faq";
import Banner from "../../../imgs/bg_cedears.jpg";
import imageyoutbe from "../../../imgs/imageYoutubepacks.jpg";
import IframeVIdeo from "../../../components/iframe_video/iframe_video";
import "./cedear-de-etf.css";
import faqjson from "../../../misc/cedears_related/faq.json";
import slick_items from "../../../misc/pack_de_cedears/slick_items_pack_cedears.json";
import { SalWrapper } from "react-sal";
import { apiRequest } from "../../../apiRquest/apiRequest";
import { Helmet } from "react-helmet";
import { graphql, Link, useStaticQuery } from "gatsby";
import ToolkitProvider, { Search } from "react-bootstrap-table2-toolkit";
import BootstrapTable from "react-bootstrap-table-next";
const pageTitle = 'Invertí en Packs de CEDEARs desde el mejor lugar| Balanz';
const pageDesc = 'Operá carteras de CEDEARs de distintas temáticas armadas por nuestros especialistas en inversiones. Abrí tu cuenta hoy y empezá a invertir';
let text = `Creemos que existen tantas inversiones como inversores, <span class='highlightsbold'>te presentamos nuestros Packs de CEDEARs.</span>`;

const { SearchBar } = Search;
const columns = [
    {
        dataField: "pack",
        text: "Pack de CEDEARs",
        sort: true,
        classes: 'bg-lt-grey text-start etf_col_cell',
        headerClasses: "pack_col",
    },
    {
        dataField: "descripción",
        text: "Descripción",
        sort: true,
        headerClasses: "descripción_col",
    },
    {
        dataField: "direct_access",
        text: "Acceso directo",
        headerClasses: "direct_access_col",
        formatter: (direct_access, row, rowIndex) => {
            //if(row.idFondo !==34 && row.idFondo !== 38){
            if(direct_access) {
                return (
                    <div className="btnholder d-grid" key={rowIndex}>
                        {/*<Link to={`/inversiones/fondos/${link}`}>*/}
                        <a href={direct_access} target="_blank">
                            <Button
                                text="Invertir"
                                variant="secondary data_table_button"/>
                        </a>
                    </div>
                );
            }
           // }
        }
    },
];

const defaultSorted = [
    {
        dataField: "name",
        order: "desc",
    },
];

const CedearsPage = () => {
    const [xs, setXs] = useState(false);
    const [dataTable] = useState([
        {
            "pack": "Dividend Yield",
            "descripción":"Selección de empresas value con altas tasas de rendimiento de dividendos.",
            "direct_access": "https://clientes.balanz.com/link?data=ew0KICAiZGF0YSI6IHsNCiAgICAicmVxdWllcmVsb2dpbiI6IjEiLA0KCSJuYW1lc3BhY2UiOiJDb3JlLkJ1c2luZXNzLkRldGFsbGVQYWNrIiwNCgkidGlja2VyIjoiUEFDSy0gRElWSURFTkQgfCBQZXNvcyBDRURFQVIiLA0KCSJwYW5lbCI6IjM4IiwNCgkicGxhem8iOiIxIiwNCgkiaWRQbGF6byI6IjEiLA0KCSJpZENhbXBhaWduIjoiRElWSURFTkRZSUVMRC1QQUNLLVdFQi0yMDI1MjgwMi1ERUVQTElOSyIsDQoJImNhbXBhaWduX2lkIjoiRElWSURFTkRZSUVMRC1QQUNLLVdFQi0yMDI1MjgwMi1ERUVQTElOSyINCiAgfX0"
        },
        {
            "pack": "CEDEARs de ETFs",
            "descripción":"Compuesto por los principales CEDEARs de ETFs, el instrumento estrella de Estados Unidos.",
            "direct_access": "https://clientes.balanz.com/link?data=ew0KICAiZGF0YSI6IHsNCiAgICAicmVxdWllcmVsb2dpbiI6IjEiLA0KCSJuYW1lc3BhY2UiOiJDb3JlLkJ1c2luZXNzLkRldGFsbGVQYWNrIiwNCgkidGlja2VyIjoiUEFDSy0gRVRGIHwgUGVzb3MgQ0VERUFSIiwNCgkicGFuZWwiOiIzOCIsDQoJInBsYXpvIjoiMSIsDQoJImlkUGxhem8iOiIxIiwNCgkiaWRDYW1wYWlnbiI6IkVURlMtUEFDSy1XRUItMjAyNTI4MDItREVFUExJTksiLA0KCSJjYW1wYWlnbl9pZCI6IkVURlMtUEFDSy1XRUItMjAyNTI4MDItREVFUExJTksiDQogIH19"
        },
        {
            "pack": "Dogs of the Dow I",
            "descripción":"Contiene 5 de las 10 empresas con mayor Dividend Yield del índice Dow Jones Industrial Average.",
            "direct_access": "https://clientes.balanz.com/link?data=ew0KICAiZGF0YSI6IHsNCiAgICAicmVxdWllcmVsb2dpbiI6IjEiLA0KCSJuYW1lc3BhY2UiOiJDb3JlLkJ1c2luZXNzLkRldGFsbGVQYWNrIiwNCgkidGlja2VyIjoiRG9ncyBvZiB0aGUgRG93IEkgfCBQZXNvcyBDRURFQVIiLA0KCSJwYW5lbCI6IjM4IiwNCgkicGxhem8iOiIxIiwNCgkiaWRQbGF6byI6IjEiLA0KCSJpZENhbXBhaWduIjoiRE9HU09GVEhFRE9XSS1QQUNLLVdFQi0yMDI1MjgwMi1ERUVQTElOSyIsDQoJImNhbXBhaWduX2lkIjoiRE9HU09GVEhFRE9XSS1QQUNLLVdFQi0yMDI1MjgwMi1ERUVQTElOSyINCiAgfX0"
        },
        {
            "pack": "Dogs of the Dow II",
            "descripción":"Contiene 5 de las 10 empresas con mayor Dividend Yield del índice Dow Jones Industrial Average.",
            "direct_access": "https://clientes.balanz.com/link?data=ew0KICAiZGF0YSI6IHsNCiAgICAicmVxdWllcmVsb2dpbiI6IjEiLA0KCSJuYW1lc3BhY2UiOiJDb3JlLkJ1c2luZXNzLkRldGFsbGVQYWNrIiwNCgkidGlja2VyIjoiRG9ncyBvZiB0aGUgRG93SUkgfCBQZXNvcyBDRURFQVIiLA0KCSJwYW5lbCI6IjM4IiwNCgkicGxhem8iOiIxIiwNCgkiaWRQbGF6byI6IjEiLA0KCSJpZENhbXBhaWduIjoiRE9HU09GVEhFRE9XSUktUEFDSy1XRUItMjAyNTI4MDItREVFUExJTksiLA0KCSJjYW1wYWlnbl9pZCI6IkRPR1NPRlRIRURPV0lJLVBBQ0stV0VCLTIwMjUyODAyLURFRVBMSU5LIg0KICB9fQ"
        },
        {
            "pack": "Semiconductores",
            "descripción":"Empresas líderes del sector de semiconductores. Es una estrategia de alta volatilidad con rebalanceo trimestral.",
            "direct_access": "https://clientes.balanz.com/link?data=ew0KICAiZGF0YSI6IHsNCiAgICAicmVxdWllcmVsb2dpbiI6IjEiLA0KCSJuYW1lc3BhY2UiOiJDb3JlLkJ1c2luZXNzLkRldGFsbGVQYWNrIiwNCgkidGlja2VyIjoiU0VNSUNPTkRVQ1RPUlMgfCBQZXNvcyBDRURFQVIiLA0KCSJwYW5lbCI6IjM4IiwNCgkicGxhem8iOiIxIiwNCgkiaWRQbGF6byI6IjEiLA0KCSJpZENhbXBhaWduIjoiU0VNSUNPTkRVQ1RPUkVTLVBBQ0stV0VCLTIwMjUyODAyLURFRVBMSU5LIiwNCgkiY2FtcGFpZ25faWQiOiJTRU1JQ09ORFVDVE9SRVMtUEFDSy1XRUItMjAyNTI4MDItREVFUExJTksiDQogIH19"
        },
        {
            "pack": "Inteligencia Artificial I",
            "descripción":"Acceso a las compañías líderes en el desarrollo de Inteligencia Artificial, tecnología que viene acaparando la atención de Wall Street. ",
            "direct_access": "https://clientes.balanz.com/link?data=ew0KICAiZGF0YSI6IHsNCiAgICAicmVxdWllcmVsb2dpbiI6IjEiLA0KCSJuYW1lc3BhY2UiOiJDb3JlLkJ1c2luZXNzLkRldGFsbGVQYWNrIiwNCgkidGlja2VyIjoiUEFDSy1JQSB8IFBlc29zIENFREVBUiIsDQoJInBhbmVsIjoiMzgiLA0KCSJwbGF6byI6IjEiLA0KCSJpZFBsYXpvIjoiMSIsDQoJImlkQ2FtcGFpZ24iOiJJTlRFTElHRU5DSUFBUlRJRklDSUFMSS1QQUNLLVdFQi0yMDI1MjgwMi1ERUVQTElOSyIsDQoJImNhbXBhaWduX2lkIjoiSU5URUxJR0VOQ0lBQVJUSUZJQ0lBTEktUEFDSy1XRUItMjAyNTI4MDItREVFUExJTksiDQogIH19"
        },
        {
            "pack": "Inteligencia Artificial II",
            "descripción":"Acceso a las compañías líderes en el desarrollo de Inteligencia Artificial, tecnología que viene acaparando la atención de Wall Street. ",
            "direct_access": "https://clientes.balanz.com/link?data=ew0KICAiZGF0YSI6IHsNCiAgICAicmVxdWllcmVsb2dpbiI6IjEiLA0KCSJuYW1lc3BhY2UiOiJDb3JlLkJ1c2luZXNzLkRldGFsbGVQYWNrIiwNCgkidGlja2VyIjoiUEFDSy1JQSBJSSB8IFBlc29zIENFREVBUiIsDQoJInBhbmVsIjoiMzgiLA0KCSJwbGF6byI6IjEiLA0KCSJpZFBsYXpvIjoiMSIsDQoJImlkQ2FtcGFpZ24iOiJJTlRFTElHRU5DSUFBUlRJRklDSUFMSUktUEFDSy1XRUItMjAyNTI4MDItREVFUExJTksiLA0KCSJjYW1wYWlnbl9pZCI6IklOVEVMSUdFTkNJQUFSVElGSUNJQUxJSS1QQUNLLVdFQi0yMDI1MjgwMi1ERUVQTElOSyINCiAgfX0"
        },
        {
            "pack": "Industrials I",
            "descripción":"CEDEARs del sector industrial, considerado la columna vertebral de la economía norteamericana. ",
            "direct_access": "https://clientes.balanz.com/link?data=ew0KICAiZGF0YSI6IHsNCiAgICAicmVxdWllcmVsb2dpbiI6IjEiLA0KCSJuYW1lc3BhY2UiOiJDb3JlLkJ1c2luZXNzLkRldGFsbGVQYWNrIiwNCgkidGlja2VyIjoiSU5EVVNUUklBTFMgSSB8IFBlc29zIENFREVBUiIsDQoJInBhbmVsIjoiMzgiLA0KCSJwbGF6byI6IjEiLA0KCSJpZFBsYXpvIjoiMSIsDQoJImlkQ2FtcGFpZ24iOiJJTlNEVVNUUklBTFNJLVBBQ0stV0VCLTIwMjUyODAyLURFRVBMSU5LIiwNCgkiY2FtcGFpZ25faWQiOiJJTlNEVVNUUklBTFNJLVBBQ0stV0VCLTIwMjUyODAyLURFRVBMSU5LIg0KICB9fQ"
        },
        {
            "pack": "Commodities",
            "descripción":"Compañías líderes del sector Commodities, con gran desempeño en los últimos años.",
            "direct_access": "https://clientes.balanz.com/link?data=ew0KICAiZGF0YSI6IHsNCiAgICAicmVxdWllcmVsb2dpbiI6IjEiLA0KCSJuYW1lc3BhY2UiOiJDb3JlLkJ1c2luZXNzLkRldGFsbGVQYWNrIiwNCgkidGlja2VyIjoiQ29tbW9kaXRpZXMgfCBQZXNvcyBDRURFQVIiLA0KCSJwYW5lbCI6IjM4IiwNCgkicGxhem8iOiIxIiwNCgkiaWRQbGF6byI6IjEiLA0KCSJpZENhbXBhaWduIjoiQ09NTU9ESVRJRVMtUEFDSy1XRUItMjAyNTI4MDItREVFUExJTksiLA0KCSJjYW1wYWlnbl9pZCI6IkNPTU1PRElUSUVTLVBBQ0stV0VCLTIwMjUyODAyLURFRVBMSU5LIg0KICB9fQ"
        },
        {
            "pack": "Vehículos Autónomos",
            "descripción":"Compañías que lideran el desarrollo de los vehículos autónomos en el mundo.",
            "direct_access": "https://clientes.balanz.com/link?data=ew0KICAiZGF0YSI6IHsNCiAgICAicmVxdWllcmVsb2dpbiI6IjEiLA0KCSJuYW1lc3BhY2UiOiJDb3JlLkJ1c2luZXNzLkRldGFsbGVQYWNrIiwNCgkidGlja2VyIjoiUEFDSy1WQSB8IFBlc29zIENFREVBUiIsDQoJInBhbmVsIjoiMzgiLA0KCSJwbGF6byI6IjEiLA0KCSJpZFBsYXpvIjoiMSIsDQoJImlkQ2FtcGFpZ24iOiJWRUhJQ1VMT1NBVVRPTk9NT1MtUEFDSy1XRUItMjAyNTI4MDItREVFUExJTksiLA0KCSJjYW1wYWlnbl9pZCI6IlZFSElDVUxPU0FVVE9OT01PUy1QQUNLLVdFQi0yMDI1MjgwMi1ERUVQTElOSyINCiAgfX0 "
        },
        {
            "pack": "5G",
            "descripción":"CEDEARs de empresas estadounidenses pioneras en el desarrollo del 5G. ",
            "direct_access": "https://clientes.balanz.com/link?data=ew0KICAiZGF0YSI6IHsNCiAgICAicmVxdWllcmVsb2dpbiI6IjEiLA0KCSJuYW1lc3BhY2UiOiJDb3JlLkJ1c2luZXNzLkRldGFsbGVQYWNrIiwNCgkidGlja2VyIjoiUEFDSy01RyB8IFBlc29zIENFREVBUiIsDQoJInBhbmVsIjoiMzgiLA0KCSJwbGF6byI6IjEiLA0KCSJpZFBsYXpvIjoiMSIsDQoJImlkQ2FtcGFpZ24iOiI1Ry1QQUNLLVdFQi0yMDI1MjgwMi1ERUVQTElOSyIsDQoJImNhbXBhaWduX2lkIjoiNUctUEFDSy1XRUItMjAyNTI4MDItREVFUExJTksiDQogIH19"
        },
        {
            "pack": "Oil & Gas",
            "descripción":"Empresas globales destacadas y líderes de la industria petrolera y gasífera internacional.",
            "direct_access": "https://clientes.balanz.com/link?data=ew0KICAiZGF0YSI6IHsNCiAgICAicmVxdWllcmVsb2dpbiI6IjEiLA0KCSJuYW1lc3BhY2UiOiJDb3JlLkJ1c2luZXNzLkRldGFsbGVQYWNrIiwNCgkidGlja2VyIjoiUEFDSy1PSUwtR0FTIHwgUGVzb3MgQ0VERUFSIiwNCgkicGFuZWwiOiIzOCIsDQoJInBsYXpvIjoiMSIsDQoJImlkUGxhem8iOiIxIiwNCgkiaWRDYW1wYWlnbiI6Ik9JTCZHQVMtUEFDSy1XRUItMjAyNTI4MDItREVFUExJTksiLA0KCSJjYW1wYWlnbl9pZCI6Ik9JTCZHQVMtUEFDSy1XRUItMjAyNTI4MDItREVFUExJTksiDQogIH19"
        },
        {
            "pack": "Next Trillion Company",
            "descripción":"Compañías mejor posicionadas para alcanzar un market cap de USD 1 Trillion. ",
            "direct_access": "https://clientes.balanz.com/link?data=ew0KICAiZGF0YSI6IHsNCiAgICAicmVxdWllcmVsb2dpbiI6IjEiLA0KCSJuYW1lc3BhY2UiOiJDb3JlLkJ1c2luZXNzLkRldGFsbGVQYWNrIiwNCgkidGlja2VyIjoiUEFDSy1UUklMTElPTiB8IFBlc29zIENFREVBUiIsDQoJInBhbmVsIjoiMzgiLA0KCSJwbGF6byI6IjEiLA0KCSJpZFBsYXpvIjoiMSIsDQoJImlkQ2FtcGFpZ24iOiJORVhUUlRJTExJT04tUEFDSy1XRUItMjAyNTI4MDItREVFUExJTksiLA0KCSJjYW1wYWlnbl9pZCI6Ik5FWFRSVElMTElPTi1QQUNLLVdFQi0yMDI1MjgwMi1ERUVQTElOSyINCiAgfX0"
        },
        {
            "pack": "Cloud & Commerce I",
            "descripción":"Posicionate en la línea de negocios que crece con mayor fuerza en las compañías que ofrecen plataformas y servicios en la nube.",
            "direct_access": "https://clientes.balanz.com/link?data=ew0KICAiZGF0YSI6IHsNCiAgICAicmVxdWllcmVsb2dpbiI6IjEiLA0KCSJuYW1lc3BhY2UiOiJDb3JlLkJ1c2luZXNzLkRldGFsbGVQYWNrIiwNCgkidGlja2VyIjoiUEFDSy1DTE9VRCB8IFBlc29zIENFREVBUiIsDQoJInBhbmVsIjoiMzgiLA0KCSJwbGF6byI6IjEiLA0KCSJpZFBsYXpvIjoiMSIsDQoJImlkQ2FtcGFpZ24iOiJDTE9VREFORENPTU1FUkNFSS1QQUNLLVdFQi0yMDI1MjgwMi1ERUVQTElOSyIsDQoJImNhbXBhaWduX2lkIjoiQ0xPVURBTkRDT01NRVJDRUktUEFDSy1XRUItMjAyNTI4MDItREVFUExJTksiDQogIH19"
        },
        {
            "pack": "Cloud & Commerce II",
            "descripción":"Posicionate en la línea de negocios que crece con mayor fuerza en las compañías que ofrecen plataformas y servicios en la nube. ",
            "direct_access": "https://clientes.balanz.com/link?data=ew0KICAiZGF0YSI6IHsNCiAgICAicmVxdWllcmVsb2dpbiI6IjEiLA0KCSJuYW1lc3BhY2UiOiJDb3JlLkJ1c2luZXNzLkRldGFsbGVQYWNrIiwNCgkidGlja2VyIjoiUEFDSy1DTE9VRCBJSSB8IFBlc29zIENFREVBUiIsDQoJInBhbmVsIjoiMzgiLA0KCSJwbGF6byI6IjEiLA0KCSJpZFBsYXpvIjoiMSIsDQoJImlkQ2FtcGFpZ24iOiJDTE9VREFORENPTU1FUkNFSUktUEFDSy1XRUItMjAyNTI4MDItREVFUExJTksiLA0KCSJjYW1wYWlnbl9pZCI6IkNMT1VEQU5EQ09NTUVSQ0VJSS1QQUNLLVdFQi0yMDI1MjgwMi1ERUVQTElOSyINCiAgfX0"
        },
        {
            "pack": "Acciones Value I",
            "descripción":"Está compuesto por compañías grandes, estables y menos volatiles que las denominadas Growth.",
            "direct_access": "https://clientes.balanz.com/link?data=ew0KICAiZGF0YSI6IHsNCiAgICAicmVxdWllcmVsb2dpbiI6IjEiLA0KCSJuYW1lc3BhY2UiOiJDb3JlLkJ1c2luZXNzLkRldGFsbGVQYWNrIiwNCgkidGlja2VyIjoiUEFDSy1WQUxVRSB8IFBlc29zIENFREVBUiIsDQoJInBhbmVsIjoiMzgiLA0KCSJwbGF6byI6IjEiLA0KCSJpZFBsYXpvIjoiMSIsDQoJImlkQ2FtcGFpZ24iOiJWQUxVRUktUEFDSy1XRUItMjAyNTI4MDItREVFUExJTksiLA0KCSJjYW1wYWlnbl9pZCI6IlZBTFVFSS1QQUNLLVdFQi0yMDI1MjgwMi1ERUVQTElOSyINCiAgfX0"
        },
        {
            "pack": "Acciones Value II",
            "descripción":"Está compuesto por compañías grandes, estables y menos volatiles que las denominadas Growth.",
            "direct_access": "https://clientes.balanz.com/link?data=ew0KICAiZGF0YSI6IHsNCiAgICAicmVxdWllcmVsb2dpbiI6IjEiLA0KCSJuYW1lc3BhY2UiOiJDb3JlLkJ1c2luZXNzLkRldGFsbGVQYWNrIiwNCgkidGlja2VyIjoiUEFDSy1WQUxVRSBJSSB8IFBlc29zIENFREVBUiIsDQoJInBhbmVsIjoiMzgiLA0KCSJwbGF6byI6IjEiLA0KCSJpZFBsYXpvIjoiMSIsDQoJImlkQ2FtcGFpZ24iOiJWQUxVRUlJLVBBQ0stV0VCLTIwMjUyODAyLURFRVBMSU5LIiwNCgkiY2FtcGFpZ25faWQiOiJWQUxVRUlJLVBBQ0stV0VCLTIwMjUyODAyLURFRVBMSU5LIg0KICB9fQ"
        },
        {
            "pack": "Tecnología",
            "descripción":"CEDEARS de compañías con alto rendimiento y gran capitalización.",
            "direct_access": "https://clientes.balanz.com/?forward=/link&data=eyJkYXRhIjp7InJlcXVpZXJlbG9naW4iOiIxIiwibmFtZXNwYWNlIjoiQ29yZS5CdXNpbmVzcy5EZXRhbGxlUGFjayIsInRpY2tlciI6IlRlY25vbG9nXHUwMEVEYSB8IFBlc29zIENFREVBUiIsInBhbmVsIjoiMzgiLCJwbGF6byI6IjIiLCJpZFBsYXpvIjoiMiJ9fQ"
        },
        {
            "pack": "Servicios Financieros",
            "descripción":"Compañías líderes en la transformación digital de la industria financiera.",
            "direct_access": "https://clientes.balanz.com/link?data=ew0KICAiZGF0YSI6IHsNCiAgICAicmVxdWllcmVsb2dpbiI6IjEiLA0KCSJuYW1lc3BhY2UiOiJDb3JlLkJ1c2luZXNzLkRldGFsbGVQYWNrIiwNCgkidGlja2VyIjoiUEFDSy1GSU5BTkNFIHwgUGVzb3MgQ0VERUFSIiwNCgkicGFuZWwiOiIzOCIsDQoJInBsYXpvIjoiMSIsDQoJImlkUGxhem8iOiIxIiwNCgkiaWRDYW1wYWlnbiI6IlNFVklDSU9TRklOQU5DSUVST1MtUEFDSy1XRUItMjAyNTI4MDItREVFUExJTksiLA0KCSJjYW1wYWlnbl9pZCI6IlNFVklDSU9TRklOQU5DSUVST1MtUEFDSy1XRUItMjAyNTI4MDItREVFUExJTksiDQogIH19"
        },
        {
            "pack": "Brasil",
            "descripción":"Algunas de las principales empresas del país que reflejan su relevancia económica y sectorial.",
            "direct_access": "https://clientes.balanz.com/link?data=ew0KICAiZGF0YSI6IHsNCiAgICAicmVxdWllcmVsb2dpbiI6IjEiLA0KCSJuYW1lc3BhY2UiOiJDb3JlLkJ1c2luZXNzLkRldGFsbGVQYWNrIiwNCgkidGlja2VyIjoiUEFDSy1CUkFTSUwgfCBQZXNvcyBDRURFQVIiLA0KCSJwYW5lbCI6IjM4IiwNCgkicGxhem8iOiIxIiwNCgkiaWRQbGF6byI6IjEiLA0KCSJpZENhbXBhaWduIjoiQlJBU0lMLVBBQ0stV0VCLTIwMjUyODAyLURFRVBMSU5LIiwNCgkiY2FtcGFpZ25faWQiOiJCUkFTSUwtUEFDSy1XRUItMjAyNTI4MDItREVFUExJTksiDQogIH19"
        },
        {
            "pack": "Pack Quant Selection",
            "descripción":"Estrategia desarrollada bajo estrictos criterios cuantitativos para crear un portfolio óptimo.",
            "direct_access": "https://clientes.balanz.com/link?data=ew0KICAiZGF0YSI6IHsNCiAgICAicmVxdWllcmVsb2dpbiI6IjEiLA0KCSJuYW1lc3BhY2UiOiJDb3JlLkJ1c2luZXNzLkRldGFsbGVQYWNrIiwNCgkidGlja2VyIjoiUEFDSy0gUVVBTlQgfCBQZXNvcyBDRURFQVIiLA0KCSJwYW5lbCI6IjM4IiwNCgkicGxhem8iOiIxIiwNCgkiaWRQbGF6byI6IjEiLA0KCSJpZENhbXBhaWduIjoiUVVBTlRTRUxFQ1RJT04tUEFDSy1XRUItMjAyNTI4MDItREVFUExJTksiLA0KCSJjYW1wYWlnbl9pZCI6IlFVQU5UU0VMRUNUSU9OLVBBQ0stV0VCLTIwMjUyODAyLURFRVBMSU5LIg0KICB9fQ"
        },
        {
            "pack": "Crypto Complex",
            "descripción":"Accedé al mercado de criptomonedas con CEDEARs de ETFs de forma fácil y regulada.",
            "direct_access": "https://clientes.balanz.com/link?data=ew0KICAiZGF0YSI6IHsNCiAgICAicmVxdWllcmVsb2dpbiI6IjEiLA0KCSJuYW1lc3BhY2UiOiJDb3JlLkJ1c2luZXNzLkRldGFsbGVQYWNrIiwNCgkidGlja2VyIjoiQ3J5cHRvfCBQZXNvcyBDRURFQVIiLA0KCSJwYW5lbCI6IjM4IiwNCgkicGxhem8iOiIxIiwNCgkiaWRQbGF6byI6IjEiLA0KCSJpZENhbXBhaWduIjoiQ1JZUFRPQ09NUExFWC1QQUNLLVdFQi0yMDI1MjgwMi1ERUVQTElOSyIsDQoJImNhbXBhaWduX2lkIjoiQ1JZUFRPQ09NUExFWC1QQUNLLVdFQi0yMDI1MjgwMi1ERUVQTElOSyINCiAgfX0"
        },
        {
            "pack": "Next Evolution",
            "descripción":"Una apuesta estratégica por la innovación y la visión a futuro.",
            "direct_access": "https://clientes.balanz.com/link?data=ew0KICAiZGF0YSI6IHsNCiAgICAicmVxdWllcmVsb2dpbiI6IjEiLA0KCSJuYW1lc3BhY2UiOiJDb3JlLkJ1c2luZXNzLkRldGFsbGVQYWNrIiwNCgkidGlja2VyIjoiTmV4dC1Fdm9sdXRpb258IFBlc29zIENFREVBUiIsDQoJInBhbmVsIjoiMzgiLA0KCSJwbGF6byI6IjEiLA0KCSJpZFBsYXpvIjoiMSIsDQoJImlkQ2FtcGFpZ24iOiJORVhURVZPTFVUSU9OLVBBQ0stV0VCLTIwMjUyODAyLURFRVBMSU5LIiwNCgkiY2FtcGFpZ25faWQiOiJORVhURVZPTFVUSU9OLVBBQ0stV0VCLTIwMjUyODAyLURFRVBMSU5LIg0KICB9fQ"
        },
        
    ]);

    const FaqsData = useStaticQuery(graphql`
        {
            allFaqs(
                filter: {category_id: {in: 19}},
                sort: {order: ASC, fields: [category_id, order]}
            ) {
                nodes {
                    category_id
                    id
                    answer
                    question
                }
            }
        }
    `);

    const [faq, setFaq] = useState({
        list: FaqsData.allFaqs.nodes
    });

    useEffect(() => {
        const handleXs = () => {
            const isXs = window.innerWidth <= 768;
            setXs(isXs);
        };

        window.addEventListener('resize', handleXs);

        handleXs();

        return () => {
            window.removeEventListener("resize", handleXs);
        };
    }, []);

  const slickSettings = {
      centerMode: false,
      slidesToShow: 5,
      dots: true,
      arrows: false,
      responsive: [
          {
              breakpoint: 2920,
              settings:"unslick"
          },
          /*{
              breakpoint: 1200,
              settings: { slidesToShow: 5, slidesToScroll: 5, infinite: false }
          },*/
          {
              breakpoint: 992,
              settings: { slidesToShow: 3, slidesToScroll: 3, infinite: false }
          },
          {
              breakpoint: 768,
              settings: { slidesToShow: 2, slidesToScroll: 2, infinite: false }
          },
          {
              breakpoint: 576,
              settings: { slidesToShow: 1, slidesToScroll: 1, infinite: false }
          }
      ]
  };
    useEffect(() => {
        apiRequest
            .get("/v1/faq/19")
            .then((res) => {
                const data = []
                res.data.data.forEach(d => {
                    data.push({
                        question: d.question,
                        answer: d.answer
                    })

                })

                setFaq((old) => ({...old, list: data}));
            })
            .catch((err) => {
                console.log(err);
            })
    }, [setFaq])
  const page = (
    <>
        <Helmet>
            <script type="application/ld+json">
                {`
                    {
                        "@context": "https://schema.org",
                        "@type": "VideoObject",
                        "name": "¿Qué son los CEDEARs?",
                        "description": "Un CEDEAR es un Certificado de Depósito Argentino que representa un activo del exterior que cotiza en Argentina a través de un certificado.",
                        "thumbnailUrl": "https://www.youtube.com/watch?v=IidfGtULiKs",
                        "uploadDate": "2022-07-08",
                        "duration": "PT0M55S",
                        "contentUrl": "https://balanz.com/inversiones/cedears/",
                        "embedUrl": "https://balanz.com/inversiones/cedears/"
                    }
                `}
            </script>

            <script type="application/ld+json">
                {`
                    {
                        "@context": "https://schema.org",
                        "@type": "VideoObject",
                        "name": "¿Cómo invertir en CEDEARs?",
                        "description": "Es muy fácil, lo hacés directamente desde nuestra plataforma.",
                        "thumbnailUrl": "https://www.youtube.com/watch?v=w3Ldre7Metc",
                        "uploadDate": "2022-07-08",
                        "duration": "PT0M55S",
                        "contentUrl": "https://balanz.com/inversiones/cedears/",
                        "embedUrl": "https://balanz.com/inversiones/cedears/"
                    }
                `}
            </script>
        </Helmet>

        <ProductBanner
          bannerContainerClassName="bannerCedear"
          bannerTrasparecy="bannerMask"
          productTitle="Packs de CEDEARs"
          bannerImage={Banner}
          bannerImageClassName="cedearsBannerImage"
          textContainerClassName="textContainer"
          bannerText={text}
          bannerTextClassName="bannerText1"
          bannerImageMobile={Banner}
          mobile={false}
        />
        <SalWrapper>
        <section className="fullwidth product-description">
            <Container>
                <Row className="">
                    <h2
                        data-sal="fade"
                        data-sal-delay="500"
                        data-sal-duration="400"
                    >¿Qué es un Pack de CEDEARs?</h2>
                </Row>
            </Container>
            <div className="bg-lt-grey"
                 data-sal="fade"
                 data-sal-delay="100"
                 data-sal-duration="200"
            >
              <Container>
                    <Row className="pb-5 pb-sm-5 pb-md-4 pb-lg-5">
                        <Col xs={12} >
                            <p className="product-text pt-2 pt-sm-3 pt-md-4 pt-lg-5">
                            Son carteras armadas por nuestros especialistas, para que inviertas en las compañías líderes de cada industria de una forma más fácil.
                                <br />
                                Permiten acceder a distintos sectores de la economía, como también invertir en tendencias destacadas del momento. 
                                Cada Pack está integrado por CEDEARs destacados por nuestros especialistas de Investment Ideas para invertir a distintos plazos y para distintos perfiles de riesgo.
                            </p>
                            <div className="pt-4 text-center"
                                 data-sal="fade"
                                 data-sal-delay="300"
                                 data-sal-duration="300"
                            >
                                <Button id="cedears_invertir_1" variant="secondary" text="Invertir en Packs de CEDEARs" onClick={() => { window.open("https://clientes.balanz.com/link?data=eyJkYXRhIjp7InJlcXVpZXJlbG9naW4iOiIxIiwibmFtZXNwYWNlIjoiQ29yZS5CdXNpbmVzcy5QYWNrcyJ9fQ", "_blank") }} />
                            </div>
                        </Col>
                    </Row>
                </Container>
            </div>
            <Container
                data-sal="fade"
                data-sal-delay="100"
                data-sal-duration="200"
            >
                <Row className="py-5 justify-content-center">
                    <Col xs={12} md={10} lg={8} className="d-lg-flex justify-content-lg-center">
                        <IframeVIdeo
                            src="https://www.youtube.com/watch?v=IidfGtULiKs"
                            resolutionClass={null}
                            thumbnail={imageyoutbe}
                        />
                    </Col>
                </Row>
                <Row className="gx-5 gy-4 pt-3 pt-sm-2 pt-md-3 pt-md-4 pt-lg-5">
                    <Col xs={12}>
                        <p className="product-text">
                        En nuestros Packs de CEDEARs se pueden encontrar empresas de gran trayectoria y reconocimiento 
                        <br/> como Apple, Tesla, Coca Cola, Amazon, Google o Nvidia, e incluso también CEDEARs de ETFs como SPY, QQQ y DIA.
                        </p>
                    </Col>
                </Row>
            </Container>
        </section>
  
        <section className="fullwidth razones">
             <Container>
                 <Row>
                    <h2 className="mt-2"
                        data-sal="fade"
                        data-sal-delay="0"
                        data-sal-duration="400"
                    >5 Razones para invertir en Packs de CEDEARs </h2>

                  <SlickProduct
                    slickSettings={slickSettings}
                    list={slick_items}
                  />
                </Row>
             </Container>
         </section>
        <section className="fullwidth rentabilidadestable">
             <Container>
                <Row className="gx-5 py-3 py-md-4 py-lg-5">
                  <Col xs={12} md={12} className='colums-table'>
                    <h2 className="mt-2">Conocé los Packs de CEDEARs que podés operar desde tu cuenta de Balanz</h2>
                    {dataTable.length > 0 && (

                        <ToolkitProvider
                            bootstrap4
                            keyField="key"
                            data={dataTable}
                            columns={columns}
                            defaultSorted={defaultSorted}
                            search
                        >
                            {
                                props => (
                                    <div className="toolkit-holders">
                                        <BootstrapTable { ...props.baseProps }
                                            classes="custom_table comparative-table table-responsive table-responsive-min"
                                            headerWrapperClasses="data_table_header"
                                            wrapperClasses="table-responsive"
                                        />
                                        
                                    </div>
                                )
                            }
                        </ToolkitProvider>
                    )}

                  </Col>
                </Row>
             </Container>
        </section>
           <section className="fullwidth como-operar"
                 data-sal="fade"
                 data-sal-delay="0"
                 data-sal-duration="400"
        >
             <Container>
                <Row className="gx-5 py-3 py-md-4 py-lg-5">
                  <Col xs={12} md={6}>
                    <h2 className="mt-2">¿Cómo invertir en Packs de CEDEARs?</h2>
                      <p className="product-text me-0 me-sm-1 me-md-3 me-lg-4 me-xl-5 mb-2">
                          Es muy fácil, lo podés hacer desde nuestra App <br/> o plataforma web. Desde tu  cuenta, ingresá a <br/>
                          Cotizaciones, seleccioná Investment Ideas y elegí el <br/> 
                          Pack de CEDEARs que quieras operar.
                          <br/>
                          Es muy importante que conozcas tu perfil de inversor <br/>
                          antes de invertir. Además, recordá que en Balanz contás
                          con tu asesor especializado para decidir cuál es la mejor opción para vos.
                      </p>
                      <div className="product-cta text-start text-md-start pb-5 pb-md-0 pt-2 pt-sm-3 pt-md-4 pt-lg-5">
                          <Button id="cedears_invertir_2" variant={`secondary ${xs ? 'chevron-right': ''}`}  text="Invertir en Packs de CEDEARs" onClick={() => { window.open("https://clientes.balanz.com/link?data=eyJkYXRhIjp7InJlcXVpZXJlbG9naW4iOiIxIiwibmFtZXNwYWNlIjoiQ29yZS5CdXNpbmVzcy5QYWNrcyJ9fQ", "_blank") }} />
                      </div>

                  </Col>
                  <Col xs={12} md={6} className="d-lg-flex justify-content-lg-end align-items-center">
                    <IframeVIdeo
                        src="https://www.youtube.com/watch?v=Anzn_W84WT4"
                      resolutionClass={null}
                    />
                  </Col>
                </Row>
             </Container>
        </section>
        

          <section className="fullwidthbg pack-de-cedears-banner-1"
               data-sal="fade"
               data-sal-delay="0"
               data-sal-duration="100"
      >
            <Container className="d-flex align-items-stretch">
                <Row className="d-flex justify-content-start align-items-center flex-grow-1 flex-shrink-1">
                    <Col xs={12} md={12}>
                        <div className="section-content">
                        <h2 className="titulo"
                                data-sal="slide-up"
                                data-sal-delay="500"
                                data-sal-duration="200"
                                data-sal-easing="ease"
                            >
                                <span className="highlightsbold">¿Querés conocer más sobre</span></h2>
                            <h2 className="titulo"
                                data-sal="slide-up"
                                data-sal-delay="500"
                                data-sal-duration="200"
                                data-sal-easing="ease"
                            ><span className="tit-60"><strong>CEDEARs?</strong></span></h2>
                            <div className="cta my-5"
                                 data-sal="slide-up"
                                 data-sal-delay="650"
                                 data-sal-duration="200"
                                 data-sal-easing="ease"
                            >
                                <Link to="/inversiones/cedears/">
                                    <Button variant="secondary" text="Ver más"/>
                                </Link>
                            </div>
                        </div>
                    </Col>
                </Row>

            </Container>
      </section>
            <br/>
            <br/>
            <br/>
        <section className="fullwidthbg pack-de-cedears-banner-2 py-4 py-md-5">
            <Container className="d-flex align-items-center justify-content-center">
                   <Row className="d-flex align-items-strech py-3 py-sm-4 py-md-4 gx-5 w-100 ">
                    <Col xs={12} md={12}>
                        <div className="section-content banner2-Title text-center"
                             data-sal="slide-up"
                             data-sal-delay="0"
                             data-sal-duration="200"
                        >
                            <h3 className="titulo me-3 me-md-3 me-lg-4 me-xl-5 mb-4" ><span className="tit-54"><strong>¿Querés empezar a invertir en Packs de CEDEARs? </strong></span></h3>
                            
                            <span className=" subtitle  me-3 me-md-3 me-lg-4 me-xl-5" ><span className="tit-54">Abrí hoy tu cuenta de inversión en Balanz, sin costo de apertura ni mantenimiento, y accedé al mejor asesoramiento del mercado.</span></span>
                            
                        </div>
                        
                    </Col>
                    <div className="cta mt-5 mb-2 text-center pt-4 p-lg-3"
                             data-sal="slide-up"
                             data-sal-delay="300"
                             data-sal-duration="200"
                        >   
                            <Link to="https://balanz.com/abrir-cuenta-2.aspx">
                                <Button variant="secondary" text="Abrir Cuenta"/>
                            </Link>

                            
                        </div> 

                  </Row>
            </Container>
        </section>

        <section className="fullwidth preguntas-frecuentes">
            <Container>
                <Row>
                  <Faq faq={faq} customClass={'cedears-faq'} />
                </Row>
              </Container>
        </section>
        </SalWrapper>
    </>
  )

    return (
        <main>
            <Layout title={pageTitle} description={pageDesc} childrem={page} category={"cedears"}></Layout>
        </main>
    );
};

// Step 3: Export your component
export default CedearsPage;
